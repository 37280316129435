.BlurArea{
    height: 300px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 15px;
    margin:0;
    padding: 0;
    backdrop-filter: blur(8px);
    box-shadow: 0px 10px 24px -1px rgba(0,0,0,0.75);
}
.BlurArea .Textinblur{
    margin-top:80px;
    text-align: center;
}
.BlurArea .Descriptioninblur{
    padding:-10px;
    margin:-70px;
}
@media (max-width:400px){
    .BlurArea .Textinblur{
        font-size: 30px;
        padding:20px;
        margin-top: 25px;
        margin-bottom:0;
    }
    .BlurArea .Descriptioninblur{
        font-size: 18px;
        padding: -1px;
        margin:-15px; 
        margin-top: -40px;
    }
}
